import Icon from "@/components/base/Icon"
import IconNames from "@/types/icons"
import { ColorType, getColor } from "@/utils"
import { Box, List, ListItem, Typography } from "@mui/material"
import { TFunction } from "i18next"
import useStyles from "./styles"

export type SummaryItem = { data: number; icon: IconNames; name: string; color: ColorType }
interface SummaryListProps {
    list: SummaryItem[]
    t: TFunction<"translation", undefined>
}

const SummaryList = ({ list, t }: SummaryListProps) => {
    const { classes } = useStyles()

    return (
        <List className={classes.list}>
            {list.map((item, k) => (
                <ListItem
                    key={k}
                    sx={{
                        color: getColor(item.color),
                    }}
                    data-testid="summary-item"
                >
                    <Typography variant="h2" component={"span"}>
                        {item.data}
                    </Typography>
                    <Box>
                        <Icon name={item.icon} />
                        <Typography component={"span"} sx={{ color: "inherit" }}>
                            {t(item.name, { multiple: Number(item.data) > 1 ? "s" : "" })}
                        </Typography>
                    </Box>
                </ListItem>
            ))}
        </List>
    )
}

export default SummaryList

import { PostData, PostEntry, Provider } from "@/types/socials"
import dayjs from "dayjs"
import { create } from "zustand"

export interface PostByDate {
    [date: string]: Partial<PostEntry>[]
}
interface PostItem {
    createdAt: string
    id: string
    locationAddress: string
    locationCity: string
    locationId: string
    locationName: string
    locationZip: string
    provider: string
    publishedAt: string
    status: string
    updatedAt: string
}
export interface ISocialStore {
    posts: PostByDate
    draftPosts: Partial<PostEntry>[]
    tempPost: Partial<PostData>
    post: Partial<PostData>
    originalPost: Partial<PostData>
    businesses: any
    currentMonth: string
    errorPost: boolean
    loadingDelete: boolean
    selectedBusinesses: any
    showPostAlert: boolean
    itemsError: any
    providerError: Provider[]
}
export interface ISocialSetter {
    update: (update: Partial<ISocialStore>) => void
    updateTempPost: (update: Partial<PostData>) => void
    flush: () => void
    flushTempPost: () => void
}

export const initSocialProvider = {
    facebook: false,
    google: false,
    instagram: false,
    numberFacebookConnected: 0,
    numberGoogleConnected: 0,
    numberInstagramConnected: 0,
    oneOfFacebookNotConnected: false,
    oneOfGoogleNotConnected: false,
    oneOfInstagramNotConnected: false,
}

const initPost: Partial<PostData> = {
    medias: [],
    description: "",
    provider: [],
    locationIds: [],
    postItems: [],
    isScheduled: false,
    mediasLocal: [],
    id: "",
    url: "",
    repost: false,
    socialProvider: initSocialProvider,
}

const initalState: ISocialStore = {
    posts: {},
    draftPosts: [],
    tempPost: null,
    post: null,
    originalPost: null,
    itemsError: null,
    currentMonth: dayjs().format("YYYY-M"),
    errorPost: false,
    businesses: null,
    selectedBusinesses: [],
    loadingDelete: false,
    showPostAlert: true,
    providerError: [],
}

const SocialStore = create<ISocialStore & ISocialSetter>((set) => ({
    ...initalState,
    update: (update) => set(() => ({ ...update })),
    updateTempPost: (update) => set((state) => ({ ...state, tempPost: { ...state.tempPost, ...update } })),
    flush: () => set(initalState),
    flushTempPost: () => set((state) => ({ ...state, tempPost: initPost })),
}))

export default SocialStore

import Icon from "@/components/base/Icon"
import SocialStore from "@/store/social"
import { Alert, AlertTitle, Collapse, IconButton, Stack, Typography } from "@mui/material"
import { useState } from "react"
import { useTranslation } from "react-i18next"

export const SocialsAlertError = () => {
    const [open, setOpen] = useState(true)
    const { update } = SocialStore()
    const { t } = useTranslation()
    return (
        <Collapse in={open}>
            <Alert
                sx={{ flexDirection: "row", gap: 1 }}
                severity="error"
                action={
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        data-testid="close-alert"
                        onClick={() => {
                            update({ errorPost: false })
                            setOpen(false)
                        }}
                    >
                        <Icon name="close" />
                    </IconButton>
                }
            >
                <AlertTitle>{t("SOCIALS.ALERT_ERROR.TITLE")}</AlertTitle>
                <Stack gap={1}>
                    <Typography variant="body2" color={"#663C00"}>
                        {t("SOCIALS.ALERT_ERROR.DESCRIPTION")}
                    </Typography>
                </Stack>
            </Alert>
        </Collapse>
    )
}

const useDeviceCheck = () => {
    const userAgent = navigator.userAgent.toLowerCase()

    return {
        isMobile: /iphone|ipod|android|blackberry|windows phone/g.test(userAgent),
        isTablet: /(ipad|tablet|playbook|silk)|(android(?!.*mobile))/g.test(userAgent),
    }
}

export default useDeviceCheck

import { ListingApi, LocationsApi, PlatformApi } from "@/api"
import { PlatformInfo } from "@/api/platform"

export type PlatformLocationMap = {
    synchronised: number
    inProgress: number
    name: string
    logo: string
    address?: string
    city?: string
    zip?: string
    id: string
}

type SummaryCount = {
    synchronised: number
    inProgress: number
    listings: number
}

export type ListingData = {
    platformMap: { [id: string]: PlatformLocationMap }
    locationMap: { [id: string]: PlatformLocationMap }
    summaryCount: SummaryCount
}

type LocationMapResponse = {
    ids: string[]
    count: number
}

type LocationMapInput = {
    user_uid: string
}

class ListingService {
    getLocationMap = async ({ user_uid }: LocationMapInput): Promise<LocationMapResponse> => {
        const locations = await LocationsApi.fetchLocationsWithFields({
            user_uid,
            fields: ["id", "name"],
        })
        const ids = locations?.data?.map((response) => response.id) || []
        return {
            ids,
            count: ids.length,
        }
    }

    getPlatformInfo = async (ids: string[]) => {
        const platforms = await PlatformApi.fetchAll(ids)
        const platformMapInfoById = {}
        for (const data of platforms.data || []) {
            platformMapInfoById[data.id] = { ...data }
        }
        return platformMapInfoById
    }

    getListingsMap = async (locationsId: string[]): Promise<ListingData> => {
        const listings = await ListingApi.fetchListings({ locationsId })
        // FRONT-CUSTOMER-4W
        // Sentry asked to manage the case when listings is null or an initerable var
        const platformIds = (listings ?? [])
            ?.map(({ platforms }) => {
                return platforms.map((platform) => platform.platformId)
            })
            .flat()
        const platformInfosById = await this.getPlatformInfo(platformIds)
        const summaryCount: SummaryCount = {
            synchronised: 0,
            inProgress: 0,
            listings: 0,
        }
        const platformMap: { [id: string]: PlatformLocationMap } = {}
        const locationMap: { [id: string]: PlatformLocationMap } = {}
        for (const {
            platforms,
            location_id,
            location_logo,
            location_name,
            location_address,
            location_city,
            location_zip,
        } of listings) {
            locationMap[location_id] ??= {
                synchronised: 0,
                inProgress: 0,
                name: location_name,
                logo: location_logo,
                address: location_address,
                city: location_city,
                zip: location_zip,
                id: location_id,
            }
            for (const platform of platforms) {
                const platformInfo = (platformInfosById[platform.platformId] || {}) as PlatformInfo
                platformMap[platform.platformId] ??= {
                    synchronised: 0,
                    inProgress: 0,
                    name: platformInfo.name,
                    logo: platformInfo.logoUrl,
                    id: platform.platformId,
                }

                // STATUS == "LIVE" Synchronisé
                if (platform.status === "LIVE") {
                    platformMap[platform.platformId].synchronised++
                    summaryCount.synchronised++
                    locationMap[location_id].synchronised++
                }
                // STATUS !== "LIVE" && STATUS !== "UNAVAILABLE" Encours
                else if (platform.status !== "UNAVAILABLE") {
                    platformMap[platform.platformId].inProgress++
                    summaryCount.inProgress++
                    locationMap[location_id].inProgress++
                }
                // Listings = Synchronisé + Encours
                summaryCount.listings++
            }
        }

        return {
            platformMap,
            locationMap,
            summaryCount,
        }
    }
}

export default new ListingService()

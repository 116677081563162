import UiPageSessionStore from "@/store/ui-page-session"
import { ListTableProps } from "@/types/listTable"
import {
    Box,
    Button,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TablePagination,
    TableRow,
} from "@mui/material"
import { Fragment, useCallback } from "react"
import { useTranslation } from "react-i18next"
import Icon from "../base/Icon"
import CustomTooltip from "../base/Tooltip"
import TablePaginationActions from "../base/table/PaginationActions"
import { TableRowsLoader } from "../skeleton/table-rows"
import useStyles from "./styles"
const RenderBtnAction = ({ canDelete, toolTipDeleteText, handleDelete, handleEdit, index, toolTipEditText }) => {
    return (
        <>
            {handleDelete && canDelete && (
                <CustomTooltip title={toolTipDeleteText}>
                    <IconButton onClick={() => handleDelete(index)}>
                        <Icon name="delete1" sx={{ color: "error.main" }} />
                    </IconButton>
                </CustomTooltip>
            )}
            {handleEdit && (
                <CustomTooltip title={toolTipEditText}>
                    <IconButton className="edit-btn" onClick={() => handleEdit(index)}>
                        <Icon name="edit" />
                    </IconButton>
                </CustomTooltip>
            )}
        </>
    )
}
const ListTable = ({
    handleChangePage,
    metadata,
    rows,
    handleDelete,
    handleEdit,
    generateLabels,
    toolTipDeleteText,
    toolTipEditText,
    toolTipRowText,
    className,
    isLoading,
}: ListTableProps) => {
    const { updateState: setStateUiPageSession } = UiPageSessionStore()
    const { t } = useTranslation()
    const { classes } = useStyles()

    const handleItemClick = useCallback(
        (row: { uid: string }) => {
            setStateUiPageSession({ selectedItem: row, showPage: "detail" })
        },
        [setStateUiPageSession]
    )

    return (
        <TableContainer className={`${classes.tableWrapper} ${className}`}>
            <Table aria-label="custom pagination table">
                <TableBody data-testid="table-test">
                    {isLoading && <TableRowsLoader data-testid="loading-skeleton" rowsNum={10} cols={2} />}
                    {!isLoading &&
                        rows?.map((row, index: number) => (
                            <TableRow
                                className={rows.length - 1 === index ? "last-child" : ""}
                                key={`${String(index)}`}
                            >
                                <Fragment key={`${String(index)}`}>
                                    {generateLabels(row).map((label: string, index: number) => (
                                        <TableCell key={`${String(index)}`} component="td" scope="row">
                                            <CustomTooltip disableTouchListener title={toolTipRowText} followCursor>
                                                <Button
                                                    className="btn-info"
                                                    data-testid={row.uid}
                                                    onClick={() => {
                                                        handleItemClick(row)
                                                    }}
                                                >
                                                    {label && label.toString()}
                                                </Button>
                                            </CustomTooltip>
                                        </TableCell>
                                    ))}
                                </Fragment>
                                {(handleEdit || handleDelete) && (
                                    <TableCell width={"115px"} sx={{ minWidth: "115px" }} component="td" scope="row">
                                        <Box className="list-action-wrapper">
                                            <RenderBtnAction
                                                toolTipDeleteText={toolTipDeleteText}
                                                canDelete={row.canDelete ?? true}
                                                handleDelete={handleDelete}
                                                handleEdit={handleEdit}
                                                index={index}
                                                toolTipEditText={toolTipEditText}
                                            />
                                        </Box>
                                    </TableCell>
                                )}
                            </TableRow>
                        ))}
                </TableBody>
                {metadata && (
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                labelDisplayedRows={({ from, to, count }) => {
                                    return "" + from + "-" + to + " " + t("USERS.PAGING") + " " + count
                                }}
                                count={metadata.total_count}
                                rowsPerPage={metadata.page_size}
                                page={metadata.page_number}
                                onPageChange={handleChangePage}
                                rowsPerPageOptions={[]}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                )}
            </Table>
        </TableContainer>
    )
}
export default ListTable

import Icon from "@/components/base/Icon"
import { IconButton, Stack, Typography } from "@mui/material"
import { useStylesBtnProvider } from "../../style"

export const PlatformSocial = ({ isDisabled, iconName, onClick, isSelected, count, hasProvider }) => {
    const { classes } = useStylesBtnProvider({ props: { hasProvider: hasProvider } })
    return (
        <IconButton
            className={classes.btnProvider}
            disabled={isDisabled}
            onClick={onClick}
            sx={{
                border: isSelected ? "1px solid #0180FF" : "none",
            }}
            data-testid={iconName}
        >
            <Stack flexDirection={"row"} gap={1} alignItems={"center"}>
                <Icon name={iconName} />
                {count > 0 && (
                    <Typography variant="body1" color={isSelected ? "text.secondary" : "text.disabled"}>
                        {count}
                    </Typography>
                )}
            </Stack>
        </IconButton>
    )
}

import { ApiService } from "@/api"
import useWindowFocus from "@/hooks/useWindowFocus"
import Layout from "@/layout"
import { CookiesService } from "@/services"
import UserSessionStore from "@/store/user-session"
import { useCallback } from "react"
import { Outlet } from "react-router-dom"
import useRedirect from "./useRedirect"

const PrivateOutlet = () => {
    const { isLoggedIn } = UserSessionStore()
    useRedirect()
    useWindowFocus({
        onFocus: useCallback(() => {
            if (isLoggedIn) {
                const { sessionChanged } = ApiService.getVerifySession()
                const user_uid = CookiesService.get("user_uid")
                //Ici on a retiré le !user_uid pour éviter d'avoir un double reload page lors de la déconnexion @Tsiory @Rija
                //Ticket : DLP-3899
                if (sessionChanged) {
                    window.location.reload()
                }
            }
        }, [isLoggedIn]),
    })

    return (
        <>
            {isLoggedIn ? (
                <Layout>
                    <Outlet />
                </Layout>
            ) : (
                <></>
            )}
        </>
    )
}

export default PrivateOutlet

import { useStyles } from "@/components/socials/style"
import useQuerySocial from "@/hooks/social/useQuerySocial"
import SocialStore from "@/store/social"
import SocialPopupStore from "@/store/social-popup"
import { Button, Stack, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"

export const SocialsAlertContentAlert = ({ handleBack }: { handleBack: () => void }) => {
    const { t } = useTranslation()
    const { classes } = useStyles()
    const { closeModal } = useQuerySocial()
    const { update } = SocialStore()
    const { showPopupContent } = SocialPopupStore()

    const handleContinue = () => {
        update({ tempPost: null, errorPost: false, showPostAlert: false })
        showPopupContent("")
        closeModal()
    }

    return (
        <Stack className={classes.contentAlert} justifyContent={"center"} gap={2} width={"100%"}>
            <Typography variant="body1" sx={{ color: "action.active" }}>
                {t("SOCIALS.ALERT.DESCRIPTION")}
            </Typography>
            <Stack className="btn-action" flexDirection={"row"} gap={2}>
                <Button color="secondary" data-testid="alert-back" onClick={handleBack}>
                    {t("CAMPAIGN.BACK")}
                </Button>
                <Button variant={"contained"} data-testid="alert-continue" type="submit" onClick={handleContinue}>
                    {t("CAMPAIGN.CONTINUE")}
                </Button>
            </Stack>
        </Stack>
    )
}

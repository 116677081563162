import Icon from "@/components/base/Icon"
import { PlatformLocationMap } from "@/services/listing"
import { Box, List, ListItem, ListItemText, Stack, Typography } from "@mui/material"
import { useMemo } from "react"
import { iconWrapper, listItemText, listSx } from "./styles"

const WebsitesList = ({ items, viewType }) => {
    const isViewTypeLocation = viewType === "location"
    const isSingleLocation = useMemo(() => {
        return items?.length === 1
    }, [items])
    return (
        <List sx={listSx()} data-testid="list-listing">
            {items.map((item: PlatformLocationMap) => (
                <ListItem key={item.id}>
                    <Stack
                        direction={"row"}
                        justifyContent={"space-between"}
                        sx={{
                            width: "100%",
                        }}
                    >
                        <Stack
                            direction={"row"}
                            gap={"16px"}
                            sx={{
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <Box
                                sx={{
                                    ...iconWrapper,
                                    borderRadius: isViewTypeLocation ? "8px" : "50% 50%",
                                    p: isViewTypeLocation ? 0 : 1,
                                }}
                            >
                                {item.logo ? (
                                    <img
                                        src={item.logo}
                                        alt={item.name}
                                        id={`item-${item.id}`}
                                        width={56}
                                        height={56}
                                        style={{ borderRadius: isViewTypeLocation ? "8px" : "50% 50%" }}
                                    />
                                ) : (
                                    <Icon name="imagePlaceholder" sx={{ minWidth: 56, height: 56 }} />
                                )}
                            </Box>
                            <Stack>
                                <ListItemText sx={listItemText} data-testid="listing-item-name">
                                    {item.name || ""}
                                </ListItemText>
                                {!isSingleLocation && (
                                    <>
                                        {item?.address && ![", ", "undefined, undefined"].includes(item.address) && (
                                            <Typography
                                                variant="h6"
                                                fontSize={"12px"}
                                                component={"span"}
                                                sx={{ color: "action.active", fontWeight: 600 }}
                                                className="ellipse"
                                            >
                                                {`${item.address}, ${item?.city}`}
                                            </Typography>
                                        )}
                                    </>
                                )}
                            </Stack>
                        </Stack>
                        <Stack direction={"column"} gap={1}>
                            <Stack gap={0.75} direction={"row"} alignItems={"center"}>
                                <Icon
                                    name="checkCircle"
                                    color="success.main"
                                    sx={{
                                        width: 24,
                                        height: 24,
                                    }}
                                />
                                <Typography
                                    component={"span"}
                                    sx={{
                                        color: "action.active",
                                        fontSize: 20,
                                        fontWeight: 500,
                                    }}
                                >
                                    {item.synchronised || 0}
                                </Typography>
                            </Stack>
                            <Stack gap={0.75} direction={"row"} alignItems={"center"}>
                                <Icon
                                    name="refreshCircle"
                                    color="warning.main"
                                    sx={{
                                        width: 24,
                                        height: 24,
                                    }}
                                />
                                <Typography
                                    component={"span"}
                                    sx={{ color: "action.active", fontSize: 20, fontWeight: 500 }}
                                >
                                    {item.inProgress || 0}
                                </Typography>
                            </Stack>
                        </Stack>
                    </Stack>
                </ListItem>
            ))}
        </List>
    )
}

export default WebsitesList

import Icon from "@/components/base/Icon"
import CustomTable, { Column } from "@/components/table/partials/custom-table"
import { convertBigNumber } from "@/utils"
import { Box, Paper, Stack, Typography } from "@mui/material"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"

type ColumnProps = Column & {
    id: string
}
export const Keywords = ({ keywordsList }) => {
    const { t } = useTranslation()
    const columns: readonly ColumnProps[] = useMemo(() => {
        return [
            {
                id: t("LOCAL_PRESENCE.REPARTITION.KEYWORD"),
                label: t("LOCAL_PRESENCE.REPARTITION.KEYWORD"),
                width: "50%",
                hidden: false,
                multiline: true,
                component: ({ row, column }) => {
                    return <Typography className="truncate">{row.value}</Typography>
                },
            },
            {
                id: t("LOCAL_PRESENCE.REPARTITION.KEYWORD"),
                label: t("LOCAL_PRESENCE.REPARTITION.SEARCH_NUMBER"),
                width: "50%",
                hidden: false,
                component: ({ row, column }) => {
                    return <Typography className="truncate">{convertBigNumber(row.count)}</Typography>
                },
            },
        ]
    }, [t])

    return (
        <Paper elevation={1}>
            <Stack flexDirection={"row"} alignItems={"center"} gap={2} paddingX={1}>
                <Icon name="search" />
                <Box>
                    <Typography data-testid="repartition-title" variant="h6">
                        {t("LOCAL_PRESENCE.REPARTITION.KEYWORD")}
                    </Typography>
                </Box>
            </Stack>
            <Stack gap={1} flexDirection={"row"} alignItems={"center"}>
                <Icon name="info" sx={{ color: "info.main" }} />
                <Typography variant="body2" color={"#014361"} fontFamily={"Poppins Medium"} fontWeight={500}>
                    {t("LOCAL_PRESENCE.REPARTITION.KEYWORD_INFO")}
                </Typography>
            </Stack>
            <CustomTable columns={columns} rows={keywordsList} showHeader={true} dataTestid="keywords" />
        </Paper>
    )
}

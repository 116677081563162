import { create } from "zustand"

export interface IServicesArea {
    servicesArea?: any
    servicesAreaDraft?: any
}

export interface IOverviewServicesAreaStoreSetter {
    updateServicesArea: (update: Partial<IServicesArea>) => void
    getInitialState: () => IServicesArea
    flush: () => void
}

const initalState = {
    servicesArea: {},
    servicesAreaDraft: null,
}

const useBusinessServicesAreaStore = create<IServicesArea & IOverviewServicesAreaStoreSetter>((set) => ({
    ...initalState,
    updateServicesArea: (update) => set(() => ({ ...update })),
    getInitialState: () => initalState,
    flush: () => set(initalState),
}))

export default useBusinessServicesAreaStore

import Icon from "@/components/base/Icon"
import { TitleWrapper } from "@/components/base/title-wrapper"
import EditModelContainer from "@/components/campaign/edit-model"
import useStyles from "@/components/campaign/styles"
import { PageProps } from "@/types/page"
import { Box, Chip, Paper, Stack, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import SmsPreview from "@/components/campaign/view/SmsPreview"
import CustomTable, { Column } from "@/components/table/partials/custom-table"
import { useCallback, useEffect, useMemo, useState } from "react"
import OverviewsStatistique from "@/components/campaign/overviews/statistique"
import { IReviews } from "@/types/campaign"
import { checkDisplayOfDate } from "@/utils"
import UiStore from "@/store/ui"
import { CampaignApi } from "@/api"
import CampaignReviewsStore from "@/store/campaign-reviews"
import EmailPreview from "@/components/campaign/view/EmailPreview"
import { iconStatus } from "@/data/constants"

type ColumnProps = Column & {
    id: string
}

interface ListRecipientProps extends PageProps {
    review: IReviews
}

const ListRecipient = ({ onClose, open, review }: ListRecipientProps) => {
    const { t } = useTranslation()
    const { classes } = useStyles()
    const [loading, setLoading] = useState<boolean>(false)
    const { reviewDetails, update, pagination, disablePagination } = CampaignReviewsStore()
    const { preferredLanguage } = UiStore()

    const handleGetSingleReview = useCallback(async () => {
        if (!reviewDetails?.pagination?._page) {
            setLoading(true)
        } else {
            update({
                disablePagination: true,
            })
        }
        const res = await CampaignApi.getSingleReview({
            campaignId: review.campaign_id,
            type: review?.type,
            _page: reviewDetails?.pagination?._page ?? 0,
            _limit: 10,
        })
        if (res?.data) {
            update({
                reviewDetails: {
                    recipients: res.data.details,
                    message: res.data.message,
                    pagination: { ...res.metadata, _page: res.metadata._page },
                },
            })
        }
        update({
            disablePagination: false,
        })
        setLoading(false)
    }, [review, reviewDetails?.pagination?._page])

    useEffect(() => {
        handleGetSingleReview()
    }, [handleGetSingleReview])

    const handleChangePage = useCallback(
        (newPage: number) => {
            update({
                reviewDetails: {
                    recipients: reviewDetails.recipients,
                    message: reviewDetails.message,
                    pagination: { ...reviewDetails.pagination, _page: newPage + 1 },
                },
            })
        },
        [pagination, reviewDetails]
    )

    const columns: readonly ColumnProps[] = useMemo(() => {
        return [
            {
                id: "contact",
                label: t("CAMPAIGN.RECIPIENTS"),

                component: ({ row, column }) => {
                    return <Box key={`${row.uid}_${column.id}`}>{row?.contact}</Box>
                },
            },
            {
                id: "status",
                label: "",
                width: "144px",
                component: ({ row, rowIndex }) => {
                    return (
                        <Stack key={rowIndex} gap={1} flexDirection={"row"} p={0}>
                            <Chip
                                label={t(`CAMPAIGN.${row?.status === "FAILED" ? "SENT" : row?.status}`)}
                                color="default"
                                variant="outlined"
                                icon={
                                    <Icon
                                        name={iconStatus?.[row?.status === "FAILED" ? "SENT" : row?.status]?.key}
                                        sx={{
                                            width: 24,
                                            color: iconStatus?.[row?.status === "FAILED" ? "SENT" : row?.status]?.color,
                                        }}
                                    />
                                }
                                sx={{ p: 0.5, "& .MuiChip-label": { p: "3px 6px" } }}
                            />
                        </Stack>
                    )
                },
            },
        ]
    }, [])

    return (
        <EditModelContainer open={open} onClose={onClose}>
            <TitleWrapper handlePreviousButton={onClose} title={t("CAMPAIGN.NEW_OPINION")} className="title-overview" />
            <Stack className={classes.blocRecipient}>
                <Stack className="bloc-left" width={"50%"}>
                    <Paper className="info-avis">
                        <Stack className="bloc-header" data-testid="location-info">
                            <Typography variant="body1">{t("CAMPAIGN.BUSINESS")}</Typography>
                            <Stack>
                                <Typography variant="body1" component="span" sx={{ color: "action.active" }}>
                                    {review.location.name}
                                </Typography>
                                <Typography
                                    variant="caption"
                                    component="span"
                                    fontWeight="600"
                                    fontFamily={"Poppins Medium"}
                                    sx={{ color: "action.active" }}
                                >
                                    {`${review.location.address}, ${review.location.city}`}
                                </Typography>
                            </Stack>
                        </Stack>
                        <Stack className="bloc-header" data-testid="sending-date">
                            <Typography variant="body1">{t("CAMPAIGN.SENDING_DATE")}</Typography>
                            <Stack>
                                <Typography variant="body2" component="span" sx={{ color: "action.active" }}>
                                    {checkDisplayOfDate(review?.created, preferredLanguage, true)}
                                </Typography>
                            </Stack>
                        </Stack>
                    </Paper>
                    <Paper className="paper-stat">
                        <OverviewsStatistique
                            stats={{
                                sent: review.stats.sent,
                                received: review.stats.total,
                                opened: review.stats.clicked,
                            }}
                        />
                    </Paper>
                    <Paper sx={{ marginBottom: 2 }}>
                        <CustomTable
                            isLoading={loading}
                            columns={columns}
                            rows={reviewDetails?.recipients}
                            pagination={{
                                page: reviewDetails?.pagination._page - 1,
                                rowsPerPage: reviewDetails?.pagination._limit,
                                totalCount: reviewDetails?.pagination._count,
                            }}
                            className={"table-contact"}
                            onPageChange={handleChangePage}
                            numberRowLoading={10}
                            disablePagination={disablePagination}
                        />
                    </Paper>
                </Stack>
                {review?.type === "sms" && (
                    <SmsPreview
                        isLoading={loading}
                        html={reviewDetails?.message?.content}
                        contacts={{
                            ...review.location,
                            subject: reviewDetails?.message?.subject,
                            lang: reviewDetails?.message?.lang,
                        }}
                        businessName={review.location.name}
                    />
                )}
                {review?.type === "email" && (
                    <EmailPreview
                        isLoading={loading}
                        html={reviewDetails?.message?.content}
                        contacts={{
                            ...review.location,
                            subject: reviewDetails?.message?.subject,
                            lang: reviewDetails?.message?.lang,
                        }}
                        businessName={review.location.name}
                    />
                )}
            </Stack>
        </EditModelContainer>
    )
}

export default ListRecipient

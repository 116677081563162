import { ResellerApi } from "@/api"
import useLocation from "@/hooks/useLocation"
import useNotification from "@/hooks/useNotification"
import useScoreStore from "@/store/overview/score/useScoreStore"
import useBusinessServicesAreaStore from "@/store/overview/services-area"
import UiPageSessionStore from "@/store/ui-page-session"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import useBusinessLogic from "../useBusinessLogic"

const useLogic = () => {
    const { navigateTo } = useBusinessLogic()
    const { t } = useTranslation()

    const { location } = useLocation()
    const { servicesArea, servicesAreaDraft, updateServicesArea } = useBusinessServicesAreaStore()
    const { refreshScoring } = useScoreStore({})
    const [loading, setLoading] = useState<boolean>(false)
    const [data, setData] = useState<{ placeId: string; description: string }[]>([])
    const { notif } = useNotification()
    const { showPage } = UiPageSessionStore()
    const defaultValue = useMemo(() => {
        if (servicesAreaDraft) {
            return servicesAreaDraft || []
        }
        return (
            location?.serviceArea?.places?.placeInfos?.map((place) => {
                return {
                    description: place.name,
                    placeId: place.placeId,
                }
            }) || []
        )
    }, [location, servicesAreaDraft, servicesArea])

    const handleSelect = useCallback((areas: any[]) => {
        const placeIds = areas.map(({ placeId }) => placeId)
        const unique = areas.filter(({ placeId }, index) => !placeIds.includes(placeId, index + 1))
        setData(unique)
    }, [])

    const handleCancel = useCallback(() => {
        updateServicesArea({ servicesAreaDraft: null })
        setData([])
        navigateTo("detail")
    }, [])

    const handlePreviousButton = useCallback(() => {
        updateServicesArea({ servicesAreaDraft: data })
        navigateTo("detail")
    }, [data])

    const onSubmit = useCallback(
        async (event) => {
            event.preventDefault()
            setLoading(true)
            const body = { ...location }
            body["serviceArea"] ??= {}
            body["serviceArea"].places ??= { placeInfos: [] }
            body["serviceArea"].places.placeInfos = data
                .filter((item) => item.placeId)
                .map((item) => ({ placeId: item.placeId, name: item.description }))
            const response = await ResellerApi.updateLocation({ ...body })

            await refreshScoring()

            if (response?.error) {
                notif({ message: t("SYSTEM_ERROR.INVALID_REQUEST"), type: "ERROR" })
            } else {
                notif({ message: t("BUSINESSES.EDIT_SUCCESS"), type: "SUCCESS" })
                //setUiPageSession({ refreshItems: true })
                updateServicesArea({
                    servicesArea: response?.serviceArea,
                    servicesAreaDraft: null,
                })
                setData([])
                navigateTo("detail")
            }
            setLoading(false)
        },
        [location, data, refreshScoring, notif, t, updateServicesArea, navigateTo]
    )

    useEffect(() => {
        if (showPage === "list") {
            updateServicesArea({ servicesAreaDraft: null })
        }
    }, [showPage, updateServicesArea])

    useEffect(() => {
        if (showPage === "edit-zone") {
            setData(defaultValue || [])
        }
    }, [showPage, defaultValue, setData])

    return { navigateTo, t, handlePreviousButton, onSubmit, handleSelect, handleCancel, loading, data }
}

export default useLogic

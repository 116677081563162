import { BlocNumber } from "@/components/analytics/bloc-number"
import { FilterAnalytics } from "@/components/analytics/filter"
import useStyles from "@/components/analytics/styles"
import Icon from "@/components/base/Icon"
import { ChartView } from "@/components/base/chart"
import TitleWrapper from "@/components/base/page-container/list-container/title-wrapper"
import { SkeletonStat } from "@/components/skeleton/statistic"
import useLogic from "@/hooks/presence-local/useLogic"
import { convertBigNumberSpace, convertPourcentageString, convertStyleString } from "@/utils"
import { Container, Paper, Stack, Typography } from "@mui/material"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { Keywords } from "@/components/analytics/keywords"
import { BusinessCompare } from "@/components/analytics/business-compare"
import { Column } from "@/components/table/partials/custom-table"
import { RenderText } from "@/components/analytics/render-text-table-to-compare"

type ColumnProps = Column & {
    id: string
}

export const LocalPresence = ({ mockData = null }) => {
    const { classes } = useStyles()
    const { t } = useTranslation()
    const {
        kpi,
        graph,
        graphs,
        handleSelectionChange,
        handleSelectionPeriodChange,
        loading,
        selectedBusinesses,
        selectedPeriod,
        keyWordsList,
        listBusinessToCompare,
    } = useLogic()

    const columnsBusinessCompare: readonly ColumnProps[] = useMemo(() => {
        return [
            {
                id: t("BUSINESSES.TITLE"),
                label: t("BUSINESSES.TITLE"),
                width: "100px",
                hidden: false,
                component: ({ row }) => {
                    return (
                        <Stack>
                            <Typography className="ellipse">{row.locationName}</Typography>
                            <Typography variant="body2" component="span" color={"#68738D"} className="ellipse">
                                {row.locationAddress}
                            </Typography>
                        </Stack>
                    )
                },
            },
            {
                id: "views_total",
                label: t("LOCAL_PRESENCE.VIEWS"),
                width: "25%",
                hidden: false,
                sortable: true,
                component: ({ row }) => <RenderText data={row.views_total} />,
            },
            {
                id: "actions_website",
                label: t("LOCAL_PRESENCE.CLICKS"),
                width: "25%",
                hidden: false,
                sortable: true,
                component: ({ row }) => <RenderText data={row.actions_website} />,
            },
            {
                id: "actions_phone",
                label: t("LOCAL_PRESENCE.CALLS"),
                width: "25%",
                hidden: false,
                sortable: true,
                component: ({ row }) => <RenderText data={row.actions_phone} />,
            },
        ]
    }, [t])

    const testKpi = () => {
        if (kpi?.period.views_total == 0 && kpi?.period.actions_website == 0 && kpi?.period.actions_phone == 0) {
            return false
        }
        return true
    }

    const dataKpi = useMemo(() => {
        return {
            views: convertBigNumberSpace(
                mockData?.period ? mockData.period?.views_total : testKpi() ? kpi?.period?.views_total : undefined,
                t("LOCAL_PRESENCE.NOT_AVAILABLE")
            ),
            clicks: convertBigNumberSpace(
                testKpi() ? kpi?.period?.actions_website : undefined,
                t("LOCAL_PRESENCE.NOT_AVAILABLE")
            ),
            calls: convertBigNumberSpace(
                testKpi() ? kpi?.period?.actions_phone : undefined,
                t("LOCAL_PRESENCE.NOT_AVAILABLE")
            ),
        }
    }, [kpi?.period, testKpi])

    return (
        <Container className={classes.container}>
            <TitleWrapper pageIcon="barChart" title={t("LOCAL_PRESENCE.TITLE_LOCAL")} />
            {(loading || !graph) && <SkeletonStat type="local_presence" />}
            {mockData === null && !loading && (
                <FilterAnalytics
                    selectedPeriod={selectedPeriod}
                    selectedBusinesses={selectedBusinesses}
                    handleSelectionChange={handleSelectionChange}
                    handleSelectionPeriodChange={handleSelectionPeriodChange}
                />
            )}

            {!loading && (kpi || mockData) && (
                <Stack className={classes.views}>
                    <BlocNumber
                        className="col-span-2"
                        label={t("LOCAL_PRESENCE.VIEWS")}
                        tooltip={t("LOCAL_PRESENCE.TOTAL_VARIATION")}
                        data-testid="viewsTotal"
                        number={dataKpi.views}
                        noData={
                            (!kpi?.period?.views_total && kpi?.period?.views_total != 0) ||
                            (!testKpi() && kpi?.period?.views_total == 0) ||
                            dataKpi.views === t("LOCAL_PRESENCE.NOT_AVAILABLE")
                        }
                        pourcentage={convertPourcentageString(kpi?.previous_period?.views_total_variation, testKpi())}
                        icon={"eye"}
                        color={"#2D97D3"}
                        type={
                            kpi?.previous_period?.views_total_variation === 0
                                ? "no-data"
                                : convertStyleString(kpi?.previous_period?.views_total_variation)
                        }
                    />
                    <BlocNumber
                        label={t("LOCAL_PRESENCE.CLICKS")}
                        tooltip={t("LOCAL_PRESENCE.WEBSITE_VARIATION")}
                        number={dataKpi.clicks}
                        noData={
                            (!kpi?.period?.actions_website && kpi?.period?.actions_website != 0) ||
                            (!testKpi() && kpi?.period?.actions_website == 0) ||
                            dataKpi.clicks === t("LOCAL_PRESENCE.NOT_AVAILABLE")
                        }
                        pourcentage={convertPourcentageString(
                            kpi?.previous_period?.actions_website_variation,
                            testKpi()
                        )}
                        icon={"globe"}
                        color={"#296ED7"}
                        type={
                            kpi?.previous_period?.actions_website_variation === 0
                                ? "no-data"
                                : convertStyleString(kpi?.previous_period?.actions_website_variation)
                        }
                    />
                    <BlocNumber
                        label={t("LOCAL_PRESENCE.CALLS")}
                        tooltip={t("LOCAL_PRESENCE.PHONE_VARIATION")}
                        number={dataKpi.calls}
                        noData={
                            (!kpi?.period?.actions_phone && kpi?.period?.actions_phone != 0) ||
                            (!testKpi() && kpi?.period?.actions_phone == 0) ||
                            dataKpi.calls === t("LOCAL_PRESENCE.NOT_AVAILABLE")
                        }
                        pourcentage={convertPourcentageString(kpi?.previous_period?.actions_phone_variation, testKpi())}
                        icon={"phoneIncoming"}
                        color={"#2A44CB"}
                        type={
                            kpi?.previous_period?.actions_phone_variation === 0
                                ? "no-data"
                                : convertStyleString(kpi?.previous_period?.actions_phone_variation)
                        }
                    />
                </Stack>
            )}
            {graph && !loading && (
                <>
                    {listBusinessToCompare?.length > 1 && (
                        <BusinessCompare details={listBusinessToCompare} columns={columnsBusinessCompare} />
                    )}
                    <Paper>
                        <Stack flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"} gap={2}>
                            <Stack flexDirection={"row"} alignItems={"center"} gap={2} px={1}>
                                <Icon name="eye" sx={{ color: "#309EDB" }} />
                                <Typography variant={"h2"} fontSize={"24px"} fontWeight="500" sx={{ color: "inherit" }}>
                                    {t("LOCAL_PRESENCE.VIEWS")}
                                </Typography>
                            </Stack>
                        </Stack>
                        <ChartView graphs={graphs.views} />
                    </Paper>
                    <Paper>
                        <Stack flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"} gap={2}>
                            <Stack flexDirection={"row"} alignItems={"center"} gap={2} px={1}>
                                <Icon name="globe" sx={{ color: "#3175DB" }} />
                                <Typography variant={"h2"} fontSize={"24px"} fontWeight="500" sx={{ color: "inherit" }}>
                                    {t("LOCAL_PRESENCE.CLICKS")}
                                </Typography>
                            </Stack>
                        </Stack>
                        <ChartView graphs={graphs.clicks} />
                    </Paper>

                    <Paper>
                        <Stack flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"} gap={2}>
                            <Stack flexDirection={"row"} alignItems={"center"} gap={2} px={1}>
                                <Icon name="phoneIncoming" sx={{ color: "#2E49DB" }} />
                                <Typography variant={"h2"} fontSize={"24px"} fontWeight="500" sx={{ color: "inherit" }}>
                                    {t("LOCAL_PRESENCE.CALLS")}
                                </Typography>
                            </Stack>
                        </Stack>
                        <ChartView graphs={graphs.calls} />
                    </Paper>
                </>
            )}
            {keyWordsList && !loading && <Keywords keywordsList={keyWordsList} />}
        </Container>
    )
}

import Icon from "@/components/base/Icon"
import useBusinessLogic from "@/hooks/businesses/useBusinessLogic"
import useLinksStore from "@/store/overview/links"
import { Box, Paper, Stack, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import ErrorMessage from "@/components/business/error-message"
import LinkAppointment from "../link-appointment"
import SocialNetwork from "../social-network"
import OverviewTitle from "../title"

const LinkBusiness = ({ isLoading, contactRef, sx, mandatoryCheck = false }) => {
    const { websiteUrl, linksNetwork } = useLinksStore()
    const { t } = useTranslation()
    const { navigateTo } = useBusinessLogic()
    return (
        <Paper
            elevation={2}
            className="clickable"
            id="links"
            data-e2e="edit-link"
            sx={sx}
            ref={contactRef}
            onClick={() => !isLoading && navigateTo("edit-link")}
        >
            <OverviewTitle
                title={t("BUSINESSES.LINK")}
                // icon="link"
                isSecondTitle
                tooltipText={t("BUSINESSES.EDIT_LINKS.MAIN_TOOLTIP")}
            />
            {mandatoryCheck && <ErrorMessage />}
            <Stack gap={2}>
                {websiteUrl && (
                    <Box className="line-wrapper">
                        <Icon name="globe" />
                        <Typography component={"span"} data-testid="website-url" className="link-url">
                            {websiteUrl}
                        </Typography>
                    </Box>
                )}
                <SocialNetwork linksNetwork={linksNetwork} />
                <LinkAppointment linksNetwork={linksNetwork} />
            </Stack>
        </Paper>
    )
}

export default LinkBusiness

import { UserApi } from "@/api"
import useNotification from "@/hooks/useNotification"
import UiPageSessionStore from "@/store/ui-page-session"
import UserSessionStore from "@/store/user-session"
import UsersStore from "@/store/users"
import { UserType } from "@/types/user"
import { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

const useLogic = () => {
    const { updateState: setStateUiPageSession } = UiPageSessionStore()
    const { notif } = useNotification()
    const [userToDelete, setUserToDelete] = useState<UserType>()
    const [loading, setLoading] = useState<boolean>(false)
    const { isConnected, profile } = UserSessionStore()
    const [metadata, setMetadata] = useState({
        page_size: 10,
        page_number: 0,
        total_count: 0,
    })
    const { users, update, refreshUsers } = UsersStore()
    const { t } = useTranslation()

    const handleChangePage = useCallback(
        (_: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
            setMetadata((prev) => ({
                ...prev,
                page_number: newPage,
            }))
            update({ refreshUsers: true })
        },
        [update]
    )

    const handleCloseModal = () => setUserToDelete(undefined)
    const handleDeleteUser = async () => {
        if (isConnected) {
            setLoading(true)
            const user_session_uid = profile.uid
            const response = await UserApi.deleteUser(userToDelete, user_session_uid)
            if (response?.error) {
                setUserToDelete(undefined)
                notif({ message: t("SYSTEM_ERROR.INVALID_REQUEST"), type: "ERROR" })
            } else {
                const previousListLength = users?.length || 0
                const page_number = previousListLength === 1 ? metadata.page_number - 1 : metadata.page_number
                setMetadata((prev) => ({
                    ...prev,
                    page_number,
                }))
                update({ refreshUsers: true })
                setUserToDelete(undefined)
                notif({ message: t("USERS.DELETE.SUCCESS"), type: "SUCCESS" })
            }
            setLoading(false)
        } else {
            setUserToDelete(undefined)
            notif({ message: t("SYSTEM_ERROR.INVALID_REQUEST"), type: "ERROR" })
        }
    }

    const refreshListUsers = useCallback(
        async (user_uid: string) => {
            setLoading(true)
            update({ refreshUsers: false })
            const response = await UserApi.fetchListUser({
                page_number: metadata.page_number + 1,
                page_size: metadata.page_size,
                user_uid,
            })
            if (response?.error) {
                notif({ message: t("SYSTEM_ERROR.INVALID_REQUEST"), type: "ERROR" })
            } else if (response?.metadata?.total_count) {
                setMetadata((prev) => ({
                    ...prev,
                    total_count: response.metadata.total_count,
                    page_size: response.metadata.page_size,
                }))
                const data = (response?.data || []).map((item) => {
                    return {
                        ...item,
                        canDelete: item.uid !== user_uid,
                    }
                })
                update({ users: [...data] })
            }
            setLoading(false)
        },
        [metadata?.page_number, metadata?.page_size, notif, t, update]
    )

    const handleDelete = useCallback(
        (index: number) => {
            if (users[index]) {
                setUserToDelete(users[index])
            }
        },
        [users]
    )

    const handleEdit = useCallback(
        (index: number) => {
            if (users[index]) {
                setStateUiPageSession({
                    selectedItem: users[index],
                    showPage: "edit",
                })
            }
        },
        [setStateUiPageSession, users]
    )
    useEffect(() => {
        return () => {
            if (typeof update === "function") {
                //Fix bug when update is not a function during unit test by Rija
                update({ refreshUsers: true, users: [] })
            }
        }
    }, [update])

    useEffect(() => {
        if (profile?.uid && refreshUsers) {
            refreshListUsers(profile?.uid)
        }
    }, [refreshUsers, refreshListUsers, profile?.uid])

    return {
        handleChangePage,
        handleDelete,
        handleEdit,
        handleCloseModal,
        handleDeleteUser,
        t,
        setStateUiPageSession,
        items: users,
        metadata,
        userToDelete,
        loading,
    }
}

export default useLogic

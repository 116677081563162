import { DispatchrService } from "@/api"
import { PostFile } from "@/api/reseller"
import { imgListExtension } from "@/data/constants"
import { PostByDate } from "@/store/social"
import { SelectType } from "@/types/select"
import { IPostItems, PostEntry, PostResponse, Provider } from "@/types/socials"
import { groupByKey, isSubset } from "@/utils"
import { getMediaRatio } from "@/utils/media-ratio"
import dayjs from "dayjs"

const statusProviderName = {
    facebook: "facebookStatus",
    google: "status",
    instagram: "instagramStatus",
}
class SocialService {
    formatMedia(postMedia: PostFile) {
        const newMedias = []
        postMedia?.medias?.forEach((media) => {
            const metadata = getMediaRatio({
                naturalSize: { width: media.dimensions.width, height: media.dimensions.height },
            })
            const mediaExtension = media?.url?.split(".").pop().toLowerCase()
            newMedias.push({
                url: media.url,
                metadata,
                type: imgListExtension.includes(mediaExtension) ? "PHOTO" : "VIDEO",
            })
        })
        return newMedias
    }

    formatPostItems({ locationIds, dateToPublish, status, providersLocation, oldPostItems, listBusiness }) {
        const postItems: IPostItems[] = []
        for (const [provider, providerLocation] of Object.entries(providersLocation)) {
            ;(providerLocation as string[]).map((location) => {
                const isInLocationIds = locationIds.find((locationId) => locationId === location)
                const oldPostItem = oldPostItems?.find(
                    (postItem) => postItem.locationId === location && postItem.provider === String(provider)
                )
                const statusKeyName = statusProviderName[provider]
                const business = listBusiness?.find((business) => business.value === location)
                const statusLocation = business.locationState?.[statusKeyName]
                const statusIsConnected = statusLocation && !["DISCONNECTED", "NOT_CONNECTED"].includes(statusLocation)
                if ((isInLocationIds && statusIsConnected) || provider === "empty") {
                    let input: IPostItems = {
                        locationId: location,
                        provider: provider === "empty" ? null : (provider as Provider),
                        scheduledAt: dateToPublish,
                        status: status,
                    }
                    if (oldPostItem) {
                        input = { ...input, postId: oldPostItem.postId, id: oldPostItem.id }
                    }
                    postItems.push(input)
                }
            })
        }

        const otherPostItems = oldPostItems?.filter(
            (item) => !postItems.find((postItem) => postItem.id === item.id || item.status !== "LIVE")
        )

        if (otherPostItems?.length > 0) {
            postItems.push(...otherPostItems)
        }

        return postItems
    }

    formatPostItemsError({ postItems, publishDate, status }) {
        const postsError = postItems.filter((item) => item.status === "FAILED")
        const postItemsError = []
        const postItemsWithoutError = []

        if (postsError.length > 0) {
            postsError.forEach(({ scheduledAtUtc, ...postError }) => {
                const newPostError = {
                    ...postError,
                    scheduledAt: publishDate,
                    status: status,
                }

                postItemsError.push(newPostError)
            })
        }

        // Collect all postItems that are not in postItemsError
        postItems.forEach(({ scheduledAtUtc, ...items }) => {
            const hasError = postItemsError.some(
                (errorItem) => errorItem.locationId === items.locationId && errorItem.provider === items.provider
            )

            if (!hasError) {
                postItemsWithoutError.push(items)
            }
        })

        return postItemsError.concat(postItemsWithoutError)
    }

    hidePostAlert(locationIds: string[]) {
        return DispatchrService.put("location-state", {
            locationIds,
            showPostAlert: false,
        })
    }

    formatRowsPostError(_rows = [], businesses: SelectType[]) {
        const rowMap = {}
        const rows = Array.isArray(_rows) ? _rows : []
        const _provider = []

        for (const row of rows) {
            const locationName = businesses.find((business) => business.value === row.locationId)?.label
            const locationAddress = businesses.find((business) => business.value === row.locationId)?.shortAddress

            rowMap[row.locationId] ??= {
                name: locationName,
                address: locationAddress,
            }
            rowMap[row.locationId][row.provider] = row.status
            if (!_provider.includes(row.provider)) {
                _provider.push(row.provider)
            }
        }

        return {
            provider: _provider,
            rows: Object.values(rowMap),
        }
    }

    getPostStatus(postItems: IPostItems[]) {
        let status = []

        if (!Array.isArray(postItems)) return ""

        for (const item of postItems) {
            status.push(item.status)
        }

        status = [...new Set(status)]

        const isFailed = status.some((item) => item === "FAILED")
        const isDraft = status.some((item) => item === "DRAFT")
        const isLive = !status.includes("FAILED") && status.includes("LIVE")
        const isScheduled = status.every((item) => item === "SCHEDULED")

        if (isFailed) return "LIVE_W_ERROR"
        if (isDraft) return "DRAFT"
        if (isLive) return "LIVE"
        if (isScheduled) return "SCHEDULED"

        return "IN_PROGRESS"
    }

    getPostProvider(postItems: IPostItems[]): Provider[] {
        if (!Array.isArray(postItems)) return []
        const provider: Provider[] = []
        for (const item of postItems) {
            if (item?.provider) provider.push(item.provider)
        }
        return [...new Set(provider)]
    }

    getPostLocationIds(postItems: IPostItems[]): IPostItems["locationId"][] {
        if (!Array.isArray(postItems)) return []
        const locationIds: IPostItems["locationId"][] = []
        for (const item of postItems) {
            locationIds.push(item.locationId)
        }
        return [...new Set(locationIds)]
    }

    formatPostForm({ post, locationIdSingle }: { post: PostResponse; locationIdSingle: string[] }) {
        const isOldPostWithDate = post?.postItems?.[0]?.scheduledAtUtc ? true : false
        const isDraftWithDate =
            this.getPostStatus(post?.postItems) === "DRAFT" && post?.postItems?.[0]?.scheduledAt ? true : false
        const isScheduled = this.getPostStatus(post?.postItems) === "SCHEDULED" || isDraftWithDate || isOldPostWithDate
        const scheduledAtWithUtc = dayjs(post?.postItems?.[0]?.scheduledAt).utc()
        const date = isScheduled ? scheduledAtWithUtc.format("YYYY-MM-DD") : dayjs().format("YYYY-MM-DD")
        const hour = isScheduled ? scheduledAtWithUtc.format("HH:mm") : ""

        const isLastDate =
            (scheduledAtWithUtc.isSame(dayjs(), "day") || scheduledAtWithUtc.isBefore(dayjs(), "day")) &&
            scheduledAtWithUtc.isBefore(dayjs(), "hour")

        return {
            provider: this.getPostProvider(post.postItems),
            description: post?.description,
            locationIds: locationIdSingle?.length > 0 ? locationIdSingle : this.getPostLocationIds(post?.postItems),
            canAddDate: isScheduled,
            hour: hour ?? "",
            date: date ?? "",
            url: post?.callToAction?.value ?? "",
            isLastPost: isLastDate,
        }
    }

    formatOtherPostParams(post: PostResponse) {
        return {
            medias: post?.medias,
            callToAction: post?.callToAction ?? null,
        }
    }

    formatPosts(posts: Partial<PostResponse>[], businesses?: string[]) {
        const newPosts = []
        posts.forEach((post) => {
            const newPostItems = post.postItems.filter((item) => businesses.includes(item.locationId))
            if (newPostItems?.length > 0) {
                const postItemsByDate = groupByKey(post.postItems, "scheduledAt")
                for (const [scheduledAt, postItems] of Object.entries(postItemsByDate)) {
                    const newPostItemsAfterFilter = (postItems as IPostItems[]).filter((item) =>
                        businesses.includes(item.locationId)
                    )
                    if (newPostItemsAfterFilter.length > 0) {
                        newPosts.push({
                            ...post,
                            postItems: postItems,
                            status: this.getPostStatus(postItems as IPostItems[]),
                            provider: this.getPostProvider(postItems as IPostItems[]),
                            locationIds: this.getPostLocationIds(postItems as IPostItems[]),
                        })
                    }
                }
            }
        })

        return newPosts
    }

    groupPostsByDay(posts: Partial<PostEntry>[]): PostByDate {
        return posts.reduce((acc, post) => {
            const currentPost = post?.postItems ?? []
            const scheduledAt = currentPost[0]?.scheduledAt
            // Extract the date part from the postItems field (YYYY-MM-DD)
            if (currentPost && currentPost.length && typeof currentPost === "object" && scheduledAt) {
                const formattedDate = scheduledAt.slice(0, 10)

                if (!acc[formattedDate]) {
                    acc[formattedDate] = []
                }

                //Verify if post is live or inprogress and group by originalDate
                if (["LIVE", "IN_PROGRESS"].includes(this.getPostStatus(post?.postItems))) {
                    const uniqueOriginalDates = [...new Set(currentPost.map((item) => item.scheduledAt.slice(0, 10)))]
                    if (uniqueOriginalDates?.length > 0) {
                        uniqueOriginalDates.forEach((originalDate: string) => {
                            const formattedOriginalDate = originalDate.slice(0, 10)

                            if (!acc[formattedOriginalDate]) {
                                acc[formattedOriginalDate] = []
                            }
                            acc[formattedOriginalDate].push(post)
                        })
                    }
                } else {
                    acc[formattedDate].push(post)
                }

                // Sort the posts for the current date
                acc[formattedDate].sort((a, b) => {
                    const statusOrder = { LIVE_W_ERROR: 0, DRAFT: 1, SCHEDULED: 2, IN_PROGRESS: 2, LIVE: 2 }

                    // Compare by status
                    const statusComparison = statusOrder[a.status] - statusOrder[b.status]
                    if (statusComparison !== 0) {
                        return statusComparison
                    }

                    // Compare by scheduledAt
                    return dayjs(a.postItems[0]?.scheduledAt).diff(dayjs(b.postItems[0]?.scheduledAt))
                })
            }

            return acc
        }, {} as PostByDate)
    }

    getMaxDateByLocationIds(locationIds: string[], listBusiness: SelectType[]) {
        const datesNow = []
        const locations = listBusiness.filter((item) => locationIds.includes(item.value as string))
        const today = dayjs().format("YYYY-MM-DD HH:mm:ss")
        locations.forEach((item) => {
            if (item?.timezone) {
                datesNow.push(dayjs(today).tz(item?.timezone).format("YYYY-MM-DD HH:mm:ss"))
            }
        })

        if (datesNow.length > 0) {
            const convertedDates = datesNow.map((date) => dayjs(date))
            // Find the maximum date
            const maxDate = dayjs.max(convertedDates)
            return maxDate
        }
        return dayjs()
    }

    mappedLocationProvider(providers: Provider[], listBusiness: SelectType[]) {
        let res = {
            facebook: [],
            google: [],
            instagram: [],
            empty: [],
        }

        const providersData = providers?.length > 0 ? providers : ["empty"]
        if (listBusiness) {
            providersData.map((provider) => {
                listBusiness.map((business) => {
                    const statusKeyName = statusProviderName[provider]
                    const status = business.locationState?.[statusKeyName]
                    if ((status && status !== "DISCONNECTED") || provider === "empty") {
                        res[provider].push(business.value)
                    }
                })
            })
        }
        return res
    }

    isDisabledForm({ postItems, listBusiness }: { postItems: IPostItems[]; listBusiness: SelectType[] }) {
        if (postItems?.length > 0 && listBusiness?.length > 0) {
            const locationIds = listBusiness.map((item) => String(item?.value))

            const postItemsLocationsIds = [...new Set(postItems.map((item) => item.locationId))]

            return !isSubset(locationIds, postItemsLocationsIds)
        }
        return false
    }

    isDraftButtonDisabled({ postItems }: { postItems: IPostItems[] }) {
        const isUniqueScheduledAt =
            new Set(postItems.map((item) => dayjs(item.scheduledAt).format("YYYY-MM-DD"))).size === 1
        return !isUniqueScheduledAt
    }
}

export default new SocialService()

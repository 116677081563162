import Icon from "@/components/base/Icon"
import { Box, CardMedia, IconButton } from "@mui/material"
import { useStyles } from "../../style"

export const MediaVideo = ({ thumbnail, isEditable, onRemove }) => {
    const { classes } = useStyles()
    return (
        <Box className={classes.imageWrapper}>
            <CardMedia
                style={{ backgroundColor: "gray", borderRadius: 8 }}
                height={98}
                width={98}
                component="video"
                image={thumbnail}
                data-testid="video-thumbnail"
            />
            {isEditable && (
                <IconButton className="btn-close" onClick={onRemove} aria-label="close">
                    <Icon name="close" />
                </IconButton>
            )}
        </Box>
    )
}

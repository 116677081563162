import Icon from "@/components/base/Icon"
import CustomTooltip from "@/components/base/Tooltip"
import { modalStyle } from "@/pages/users/styles"
import { withSxProp } from "@/utils"
import { Box, Button, CircularProgress, IconButton, Modal, Paper, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { deleteModal } from "./styles"

interface PropsModal {
    open: boolean
    onClose: () => void
    loading: boolean
    onDelete: () => void
    title: string
    description: string
    popupContainer?: any
}

export const DeleteModal = ({ open, onClose, loading, onDelete, title, description, popupContainer }: PropsModal) => {
    const { t } = useTranslation()
    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-remove"
            disableRestoreFocus
            container={() => popupContainer?.current}
            sx={{
                position: popupContainer?.current && popupContainer?.current !== undefined ? "absolute" : "fixed",
            }}
        >
            <Paper elevation={6} sx={withSxProp(modalStyle, deleteModal)} data-testid="modal-content">
                <Box className="title-wrapper">
                    <Typography id="modal-modal-title" variant="h2">
                        {title}
                    </Typography>
                    <CustomTooltip title={t("USERS.CLOSE")}>
                        <IconButton onClick={onClose}>
                            <Icon name="x" />
                        </IconButton>
                    </CustomTooltip>
                </Box>
                <Typography id="modal-remove">{description}</Typography>
                <Box className="action-wrapper">
                    <Button onClick={onClose}>{t("REPUTATION.CANCEL")}</Button>
                    <Button className="btn-remove" onClick={onDelete} variant="contained" disabled={loading}>
                        {loading && <CircularProgress size={16} />}
                        {!loading && <Icon name="trash" />}
                        {t("REPUTATION.REMOVE")}
                    </Button>
                </Box>
            </Paper>
        </Modal>
    )
}

import useBusinessLogic from "@/hooks/businesses/useBusinessLogic"
import useLoadGoogleMap from "@/hooks/useLoadGoogleMap"
import useLocationStore from "@/store/location"
import useBusinessServicesAreaStore from "@/store/overview/services-area"
import UiStore from "@/store/ui"
import { translateGooglePlaceFields } from "@/utils/googlePlaceTranslator"
import { Box, Paper } from "@mui/material"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import BoxChip from "../../box-chip"
import OverviewTitle from "../title"

const OverviewServiceArea = ({ serviceAreaRef, isLoading, data }) => {
    const isGoogleMapsLoaded = useLoadGoogleMap() // Vérifie si Google Maps est chargé
    const { navigateTo } = useBusinessLogic()
    const { location } = useLocationStore()
    const { preferredLanguage } = UiStore()
    const { t } = useTranslation()
    const { servicesArea, updateServicesArea } = useBusinessServicesAreaStore()
    const [translatedAreas, setTranslatedAreas] = useState([])
    const [isTranslating, setIsTranslating] = useState(false)

    const servicesAreaMap = useMemo(() => {
        const items = data?.serviceArea || location?.serviceArea?.places?.placeInfos

        if (items?.length > 0) {
            return items.map((element) => ({
                id: element?.placeId,
                label: element.name,
                placeId: element?.placeId,
            }))
        }
        return []
    }, [data?.serviceArea, location, servicesArea])

    const translateAreas = useCallback(async () => {
        if (!servicesAreaMap?.length || !isGoogleMapsLoaded) {
            setTranslatedAreas([])
            return
        }

        setIsTranslating(true)
        try {
            const translated = await translateGooglePlaceFields(
                servicesAreaMap.map((area) => ({
                    description: area.label,
                    placeId: area.placeId,
                })),
                preferredLanguage
            )

            setTranslatedAreas(
                translated.map((item) => ({
                    id: item.placeId,
                    label: item.description,
                    placeId: item.placeId,
                }))
            )
        } catch (error) {
            console.error("Translation error:", error)
            setTranslatedAreas(servicesAreaMap)
        } finally {
            setIsTranslating(false)
        }
    }, [servicesAreaMap, preferredLanguage, isGoogleMapsLoaded])

    useEffect(() => {
        translateAreas()
    }, [servicesAreaMap, preferredLanguage, isGoogleMapsLoaded])

    useEffect(() => {
        if (data?.serviceArea || location?.serviceArea) {
            updateServicesArea({
                servicesArea: data?.serviceArea || location?.serviceArea,
            })
        }
    }, [data?.serviceArea, location?.serviceArea])

    return (
        <Paper
            elevation={2}
            className="clickable"
            id="services-area"
            data-testid="services-area"
            ref={serviceAreaRef}
            onClick={() => !isLoading && navigateTo("edit-zone")}
            data-e2e="areas-served"
        >
            <OverviewTitle
                title={t("BUSINESSES.AREAS_SERVED")}
                isSecondTitle
                tooltipText={t("BUSINESSES.EDIT_AREAS_SERVED.MAIN_TOOLTIP")}
            />
            {translatedAreas?.length > 0 && !isLoading && !isTranslating && (
                <Box className="chip-wrapper" data-e2e="area-business" data-testid="area-business">
                    <BoxChip items={translatedAreas} isAction={false} />
                </Box>
            )}
        </Paper>
    )
}

export default OverviewServiceArea

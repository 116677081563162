export const TO_RADIANS = Math.PI / 180

export const iconStatus = {
    RECEIVED: { key: "campaignSent", color: "#377BFF" },
    CLICKED: { key: "campaignOpened", color: "#00D863" },
    FAILED: { key: "send", color: "#B337FF" },
    SENT: { key: "send", color: "#B337FF" },
}

export const imgListExtension = ["jpg", "jpeg", "png"]
export const videoListExtension = ["mp4", "mov", "m4v"]

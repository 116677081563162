import SocialPopupStore from "@/store/social-popup"
import dayjs from "dayjs"
import useQueryParams from "../useQueryParams"

const useQuerySocial = () => {
    const { setQueryParams, removeQueryParams } = useQueryParams()
    const { flush: flushSocialPopupStore } = SocialPopupStore()
    const createPost = (scheduled?: boolean, date?: string) => {
        const keys = {
            "create-post": "1",
        }
        if (scheduled) {
            keys["scheduled"] = date
        }
        setQueryParams(keys)
    }

    const editPost = (id = "", date = null) => {
        let query = { "edit-post": id, show: "single_post" }
        if (date) Object.assign(query, { day: dayjs(date).format("YYYY-MM-DD") })
        setQueryParams(query)
    }

    const deletePost = (id = "") => {
        setQueryParams({ "delete-post": id })
    }

    const openPosts = (date) => {
        setQueryParams({ "multi-post": "true", day: dayjs(date).format("YYYY-MM-DD") })
    }

    const openCalendar = (month: string) => {
        setQueryParams({ month })
    }

    const closeModal = () => {
        flushSocialPopupStore()
        removeQueryParams(["create-post", "edit-post", "multi-post", "delete-post", "show", "scheduled", "day"])
    }
    return {
        createPost,
        editPost,
        deletePost,
        closeModal,
        openPosts,
        openCalendar,
    }
}

export default useQuerySocial

import env from "@/config/env"
import { useCallback, useEffect, useState } from "react"

const useLoadGoogleMap = () => {
    const [isLoaded, setIsLoaded] = useState(false)

    const loadScript = useCallback((src: string, position: HTMLElement | null, id: string) => {
        if (!position) {
            return
        }
        const script = document.createElement("script")
        script.setAttribute("async", "false")
        script.setAttribute("id", id)
        script.src = src
        script.onload = () => {
            console.log("Google Maps script loaded.")
            setIsLoaded(true) // Met à jour l'état à "chargé"
        }
        script.onerror = () => {
            console.error("Failed to load Google Maps script.")
        }
        position.appendChild(script)
    }, [])

    useEffect(() => {
        if (!document.querySelector("#google-maps")) {
            loadScript(
                `https://maps.googleapis.com/maps/api/js?key=${env.GOOGLE_MAPS_API_KEY}&libraries=places&callback=initMap`,
                document.querySelector("head"),
                "google-maps"
            )
            ;(window as any).initMap = () => {
                console.log("Init google places")
            }
        } else if ((window as any)?.google?.maps) {
            setIsLoaded(true) // Si déjà chargé, on met à jour immédiatement
        }
    }, [loadScript])

    return isLoaded // Retourne l'état de chargement
}

export default useLoadGoogleMap

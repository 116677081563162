import BusinessAutocomplete from "@/components/autocomplete/business-autocomplete"
import Icon from "@/components/base/Icon"
import CustomTooltip from "@/components/base/Tooltip"
import { BoxUploadImage } from "@/components/base/box-upload-image"
import { DatePickerCustom } from "@/components/base/date-picker"
import BaseInput from "@/components/base/input"
import BaseSelect from "@/components/base/select"
import InputEmojiCustom from "@/components/input-emoji"
import SocialStore from "@/store/social"
import UiStore from "@/store/ui"
import { Provider } from "@/types/socials"
import { generateTimeSlots, getNextAvailableTimes } from "@/utils"
import {
    Box,
    Button,
    capitalize,
    Checkbox,
    CircularProgress,
    FormControlLabel,
    IconButton,
    Stack,
    Typography,
} from "@mui/material"
import dayjs from "dayjs"
import { Fragment, useMemo } from "react"
import { Controller } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { SocialsAlertError } from "../alert/error"
import { GoogleAlertImage } from "../alert/google-image-info"
import { SocialsAlertPostError } from "../alert/post-error"
import { useStyles } from "../style"
import { MediaPhoto } from "./media-photo"
import { MediaVideo } from "./media-video"
import { PlatformSocial } from "./platform"
import usePostForm from "./useForm"
import SocialsAlertAccountDisconnected from "../alert/account-disconnected"

const SocialsForm = () => {
    const { t } = useTranslation()
    const { classes } = useStyles()
    const { preferredLanguage } = UiStore()
    const { errorPost, selectedBusinesses } = SocialStore()

    const {
        formState,
        formValues,
        socialProvider,
        onBusinessChange,
        handleProvider,
        includeProvider,
        handleMedias,
        control,
        onChangeDate,
        handleSubmit,
        onSubmit,
        handleRemoveMedia,
        errorUpload,
        isOpenMedia,
        handleModalMedia,
        loading,
        tempPost,
        handleSaveInDraft,
        loadingDraft,
        isEditable,
        postShouldHaveCallToAction,
        validDraft,
        isInvalidMedias,
        isModify,
        handleReusePost,
        isPostError,
        handleDeletePost,
        isSingleLocation,
        addLink,
        isEditableDate,
        handleRemoveLink,
        minDate,
        setErrorUpload,
        setUrl,
        setDescription,
        setAddLink,
        selectedDate,
        isDisableForm,
        isBtnDraftDisabled,
        totalMedia,
    } = usePostForm()

    const selectBusinessLabel = useMemo(() => {
        if (formValues?.locationIds?.length === 0 || formValues?.locationIds?.length > 1) {
            return t("BUSINESSES.TITLE")
        }
        return `${t("BUSINESSES.TITLE_SINGULAR")}`
    }, [formValues?.locationIds?.length, t])

    const labelBtnSubmit = useMemo(() => {
        if (errorPost) return t("SOCIALS.RETRY")
        else if (formValues?.canAddDate) return t("SOCIALS.PROGRAM")
        return t("SOCIALS.POST_NOW")
    }, [errorPost, formValues?.canAddDate, t])

    return (
        <Box
            className="inputs-container container-row"
            sx={{
                width: "100%",
            }}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <Stack className="inputs-wrapper" gap={2}>
                    <Stack className={classes.headerPost}>
                        {!isSingleLocation && (
                            <Box className="input-business">
                                <BusinessAutocomplete
                                    required={true}
                                    label={selectBusinessLabel}
                                    defaultValue={formValues?.locationIds}
                                    selectionChange={onBusinessChange}
                                    disabled={!isEditable || isDisableForm}
                                />
                            </Box>
                        )}
                        <Stack flexDirection={"row"} gap={2} alignItems={"center"}>
                            <Stack
                                flexDirection={"row"}
                                gap={0.5}
                                sx={{
                                    "& svg": {
                                        color: "transparent",
                                    },
                                }}
                            >
                                {["facebook", "instagram", "google"].map((provider: Provider) => {
                                    const capitalizedProviderName = capitalize(provider)
                                    return (
                                        <PlatformSocial
                                            iconName={
                                                !socialProvider?.[provider]
                                                    ? `grey${capitalizedProviderName}`
                                                    : includeProvider(provider)
                                                    ? `colored${capitalizedProviderName}`
                                                    : `colored${capitalizedProviderName}`
                                            }
                                            onClick={() => handleProvider(provider)}
                                            isDisabled={!socialProvider?.[provider] || !isEditable || isDisableForm}
                                            isSelected={includeProvider(provider)}
                                            count={
                                                socialProvider?.[`number${capitalizedProviderName}Connected`] > 0 &&
                                                formValues?.locationIds?.length > 1
                                                    ? socialProvider?.[`number${capitalizedProviderName}Connected`]
                                                    : 0
                                            }
                                            hasProvider={socialProvider?.[provider]}
                                        />
                                    )
                                })}
                            </Stack>
                        </Stack>
                    </Stack>
                    {isPostError && <SocialsAlertPostError />}
                    {selectedBusinesses?.length > 0 && (
                        <>
                            {(socialProvider?.oneOfFacebookNotConnected ||
                                socialProvider?.oneOfGoogleNotConnected ||
                                socialProvider?.oneOfInstagramNotConnected) && <SocialsAlertAccountDisconnected />}
                        </>
                    )}

                    <Stack className={classes.blocTextarea}>
                        <InputEmojiCustom
                            text={formValues?.description}
                            setText={setDescription}
                            isModify={false}
                            responseByAi={false}
                            isLoading={false}
                            loadingAI={false}
                            maxLength={1500}
                            isLimitExceeded={false}
                            suggest={false}
                            displayBtn={false}
                            placeholder={t("SOCIALS.PLACEHOLDER_INPUT")}
                            disabled={(!isModify && !isEditable) || isDisableForm}
                        />
                    </Stack>
                    {postShouldHaveCallToAction && (
                        <>
                            {!addLink && (
                                <Stack width={"fit-content"}>
                                    <Button
                                        variant="text"
                                        className="w-auto"
                                        onClick={() => setAddLink(true)}
                                        disabled={!isEditable || isDisableForm}
                                        data-testid="add-link"
                                    >
                                        {t("SOCIALS.ADD_LINK")}
                                    </Button>
                                </Stack>
                            )}
                            {addLink && (
                                <Stack flexDirection={"row"} gap={1}>
                                    <BaseInput
                                        disabled={!isEditable || isDisableForm}
                                        label={t("SOCIALS.LINK")}
                                        value={formValues?.url}
                                        onChange={(e) => setUrl((e.target.value || "").trim())}
                                        helperText={formState?.errors["url"] && t("SOCIALS.URL.INVALID_LINK")}
                                        error={!!formState?.errors["url"]}
                                        max={1945}
                                        data-testid="input-link"
                                        endAdornment={
                                            formValues?.provider?.includes("instagram") && (
                                                <CustomTooltip title={t("SOCIALS.URL.TOOLTIP")}>
                                                    <IconButton className="without-hover">
                                                        <Icon name="instagramInfo" />
                                                    </IconButton>
                                                </CustomTooltip>
                                            )
                                        }
                                        sx={{
                                            width: "100%",
                                            "& svg": {
                                                color: "transparent",
                                            },
                                        }}
                                    />
                                    <IconButton
                                        className="without-hover"
                                        disabled={!isEditable || isDisableForm}
                                        onClick={handleRemoveLink}
                                        data-testid="remove-link"
                                    >
                                        <Icon
                                            name="delete1"
                                            sx={{
                                                color: isEditable || !isDisableForm ? "error.main" : "text.disabled",
                                            }}
                                        />
                                    </IconButton>
                                </Stack>
                            )}
                        </>
                    )}
                    {formValues?.provider.includes("google") && tempPost?.mediasLocal?.length > 1 && (
                        <GoogleAlertImage />
                    )}

                    <Stack
                        flexDirection={isInvalidMedias ? "column" : "row"}
                        gap={isInvalidMedias ? "4px" : { xs: 1, sm: 2 }}
                    >
                        <>
                            <Stack gap={{ xs: 1, sm: 2 }} direction={"row"} flexWrap="wrap" width={"100%"}>
                                {tempPost?.medias &&
                                    tempPost?.medias?.length > 0 &&
                                    tempPost?.medias?.map((media, k) => (
                                        <Fragment key={k}>
                                            {media.type === "PHOTO" && (
                                                <MediaPhoto
                                                    url={media.url}
                                                    name={media.file?.name}
                                                    isEditable={isEditable && !isDisableForm}
                                                    onRemove={() => handleRemoveMedia(media)}
                                                />
                                            )}
                                            {media.type === "VIDEO" && (
                                                <MediaVideo
                                                    thumbnail={media.url}
                                                    isEditable={isEditable && !isDisableForm}
                                                    onRemove={() => handleRemoveMedia(media)}
                                                />
                                            )}
                                        </Fragment>
                                    ))}
                                {tempPost?.mediasLocal?.length > 0 &&
                                    tempPost?.mediasLocal?.map((media, k) => (
                                        <Fragment key={media.file.name}>
                                            {media.file && media.type === "PHOTO" && (
                                                <MediaPhoto
                                                    url={URL.createObjectURL(media.file)}
                                                    name={media.file?.name}
                                                    isEditable={isEditable && !isDisableForm}
                                                    onRemove={() => handleRemoveMedia(media)}
                                                />
                                            )}
                                            {media.file && media.type === "VIDEO" && (
                                                <MediaVideo
                                                    thumbnail={URL.createObjectURL(media.file)}
                                                    isEditable={isEditable && !isDisableForm}
                                                    onRemove={() => handleRemoveMedia(media)}
                                                />
                                            )}
                                        </Fragment>
                                    ))}
                                {isEditable && !isDisableForm && totalMedia < 10 && (
                                    <>
                                        <BoxUploadImage
                                            isOpen={isOpenMedia}
                                            mediaNumber={totalMedia}
                                            handleMedias={handleMedias}
                                            errorUpload={errorUpload}
                                            handleModalMedia={handleModalMedia}
                                            setErrorUpload={setErrorUpload}
                                            error={isInvalidMedias}
                                        />
                                        {isInvalidMedias && (
                                            <Box>
                                                <Typography
                                                    component={"p"}
                                                    className="helper-text"
                                                    variant="caption"
                                                    sx={{
                                                        color: "error.main",
                                                    }}
                                                >
                                                    {t("SOCIALS.MUST_ADD_MEDIAS_INSTAGRAM")}
                                                </Typography>
                                            </Box>
                                        )}
                                    </>
                                )}
                            </Stack>
                        </>
                    </Stack>

                    <Stack>
                        <Controller
                            name="canAddDate"
                            control={control}
                            defaultValue={formValues?.canAddDate ?? false}
                            render={({ field: { onChange, value } }) => (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            disabled={isEditableDate || isDisableForm}
                                            checked={value ?? false}
                                            onChange={(e) => onChange(e.target.checked)}
                                            data-testid="add-date"
                                        />
                                    }
                                    label={
                                        <Stack direction="row" alignItems="center" spacing={1} data-e2e="add-date">
                                            <Typography color="action.active">{t("SOCIALS.ADD_DATE")}</Typography>
                                        </Stack>
                                    }
                                    sx={{ width: "fit-content" }}
                                />
                            )}
                        />
                        {formValues?.canAddDate && (
                            <Stack flexDirection="row" gap={2} width={"100%"} data-testid="date-hour">
                                <DatePickerCustom
                                    desktopModeMediaQuery="@media anypointer:fine"
                                    label={t("INPUT.DATE_LABEL")}
                                    preferredLanguage={preferredLanguage}
                                    sx={{ width: "100%" }}
                                    defaultValue={formValues?.date ? dayjs(formValues?.date) : null}
                                    readOnly={true}
                                    shrink={formValues?.date ? true : false}
                                    showLabelStar={true}
                                    value={formValues?.date ? dayjs(formValues?.date) : null}
                                    onChange={(value) => onChangeDate(value)}
                                    minDate={minDate}
                                    disabled={isEditableDate || isDisableForm}
                                    noBottomBorder={false}
                                    closeOnSelect={true}
                                />
                                <BaseSelect
                                    optionsAvailable={getNextAvailableTimes(selectedDate) || []}
                                    options={generateTimeSlots("00:00", "24:00", 30)}
                                    controlName="hour"
                                    label={t("INPUT.HOUR_LABEL")}
                                    defaultValue={formValues?.hour}
                                    control={control}
                                    disabled={isEditableDate || isDisableForm}
                                    formStateError={!!formState?.errors?.hour}
                                />
                            </Stack>
                        )}
                    </Stack>
                    {errorPost && <SocialsAlertError />}
                    {isEditable && (
                        <Stack className={classes.btnAction}>
                            {!errorPost && (
                                <Button
                                    variant="outlined"
                                    type="button"
                                    disabled={
                                        !validDraft || loadingDraft || loading || isDisableForm || isBtnDraftDisabled
                                    }
                                    sx={{ boxShadow: "none" }}
                                    onClick={handleSaveInDraft}
                                    data-testid="save-in-draft"
                                >
                                    {loadingDraft && <CircularProgress size={16} sx={{ marginRight: "8px" }} />}
                                    {t("SOCIALS.SAVE_IN_DRAFT")}
                                </Button>
                            )}
                            <Button
                                variant="contained"
                                type="submit"
                                data-testid="submit"
                                disabled={!formState?.isValid || loading || loadingDraft || isDisableForm}
                            >
                                {loading && <CircularProgress size={16} sx={{ marginRight: "8px" }} />}
                                {labelBtnSubmit}
                            </Button>
                        </Stack>
                    )}
                    {!isEditable && !isPostError && (
                        <Stack className={classes.btnAction}>
                            {/* <Button
                                variant="contained"
                                type="button"
                                color="secondary"
                                disabled={true}
                                sx={{ boxShadow: "none", visibility: "hidden" }}
                                onClick={() => (isModify ? handleModify(false) : handleModify(true))}
                            >
                                {loading && <CircularProgress size={16} sx={{ marginRight: "8px" }} />}
                                {!isModify ? t("CAMPAIGN.MODIFY") : t("REPUTATION.CANCEL")}
                            </Button> */}

                            <Button
                                variant="contained"
                                type="button"
                                data-testid="reuse-post"
                                disabled={isDisableForm}
                                onClick={() => handleReusePost(tempPost)}
                            >
                                {loading && <CircularProgress size={16} sx={{ marginRight: "8px" }} />}
                                {t("SOCIALS.REUSE")}
                            </Button>
                        </Stack>
                    )}
                    {isPostError && (
                        <Stack className={classes.btnAction}>
                            <Button
                                variant="outlined"
                                type="button"
                                color="error"
                                data-testid="remove-post"
                                disabled={isDisableForm}
                                sx={{ boxShadow: "none" }}
                                onClick={() => handleDeletePost()}
                            >
                                {t("CAMPAIGN.REMOVE")}
                            </Button>

                            <Button
                                variant="contained"
                                type="submit"
                                data-testid="submit"
                                disabled={!formState?.isValid || loading || isDisableForm}
                            >
                                {loading && <CircularProgress size={16} sx={{ marginRight: "8px" }} />}
                                {labelBtnSubmit}
                            </Button>
                        </Stack>
                    )}
                </Stack>
            </form>
        </Box>
    )
}

export default SocialsForm

import { CookiesService, NotificationService } from "@/services"
import UiStore from "@/store/ui"
import { Button, Snackbar } from "@mui/material"
import { useCallback } from "react"
import { useTranslation } from "react-i18next"

const SnackbarNewVersion = () => {
    const { t } = useTranslation()
    const { showNewVersion, appVersion } = UiStore()

    const resetCookies = useCallback(() => {
        CookiesService.clearOldCookies()
        NotificationService.showNewVersion(false)
        window.location.reload()
    }, [appVersion, showNewVersion])

    return (
        <Snackbar
            data-testid="snackbar-new-version"
            open={showNewVersion}
            sx={{
                "& .MuiSnackbarContent-root": {
                    flexDirection: "row",
                    backgroundColor: "rgb(0, 4, 31)",
                    paddingY: "14px",
                    width: "auto",
                },
            }}
            anchorOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            message={t("COMMON.NEW_APP_VERSION")}
            action={
                <Button color="secondary" size="small" onClick={resetCookies}>
                    {t("COMMON.RELOAD")}
                </Button>
            }
            ClickAwayListenerProps={{
                onClickAway: () => null,
            }}
        />
    )
}

export default SnackbarNewVersion

import Icon from "@/components/base/Icon"
import { Box, IconButton } from "@mui/material"
import { useStyles } from "../../style"

export const MediaPhoto = ({ url, name, isEditable, onRemove }) => {
    const { classes } = useStyles()
    return (
        <Box className={classes.imageWrapper}>
            <img src={url} alt={name} height={98} width={98} />
            {isEditable && (
                <IconButton className="btn-close" onClick={onRemove} aria-label="close">
                    <Icon name="close" />
                </IconButton>
            )}
        </Box>
    )
}

import Icon from "@/components/base/Icon"
import CustomTooltip from "@/components/base/Tooltip"
import IconNames from "@/types/icons"
import { convertToStarArray, getIconStar } from "@/utils"
import { Box, IconButton, Paper, Stack, Typography } from "@mui/material"
import { useMemo } from "react"

interface IBlocNumber {
    label: string
    icon: IconNames
    number: string
    pourcentage: string
    color: string
    tooltip: string
    noData: any
    score: number | string
    type: "no-data" | "superior" | "inferior"
    className?: string
}

export const BlocStar = ({
    label,
    icon,
    number,
    score,
    pourcentage,
    color,
    type,
    tooltip,
    noData,
    className,
}: IBlocNumber) => {
    const stars = useMemo(() => {
        const nb = score || 0
        const scoreArray = convertToStarArray(nb as number)
        return scoreArray
    }, [score])

    return (
        <Paper className={`item-view ${className}`} sx={{ background: color }}>
            <Stack flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"}>
                <Stack flexDirection={"row"} alignItems={"center"} gap={2}>
                    <Typography variant={"body1"} fontWeight="500" sx={{ color: "inherit" }}>
                        {label}
                    </Typography>
                </Stack>
                <CustomTooltip title={tooltip}>
                    <IconButton sx={{ color: "white", padding: 0 }}>
                        <Icon name="info" sx={{ width: "20px", height: "20px" }} />
                    </IconButton>
                </CustomTooltip>
            </Stack>
            <Stack flexDirection={"row"} alignItems={"center"} gap={"16px"} flexWrap="wrap">
                {!noData && (
                    <>
                        <Typography variant={"h2"} className="text-number" data-testid={label}>
                            {number}
                        </Typography>
                        <Stack flexDirection={"row"} data-e2e="review-stars">
                            {stars.map((i, k) => (
                                <Icon key={k} name={getIconStar(i)} sx={{ color: "white" }} />
                            ))}
                        </Stack>
                    </>
                )}
                {noData && <Typography className="text-nodata">{number}</Typography>}
                {pourcentage != undefined && (
                    <Box className={`pourcentage ${type}`}>
                        <Typography variant={"body2"} component="span" sx={{ color: "inherit" }}>
                            {pourcentage}
                        </Typography>
                    </Box>
                )}
            </Stack>
        </Paper>
    )
}

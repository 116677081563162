import { PayloadPost, PostResponse } from "@/types/socials"
import { DispatchrService } from "../"
import { ErrorResponse } from "../base"
import { AxiosError } from "axios"

export interface FetchPostBodyType {
    month: number
    year: number
    locationIds: string[]
    fields: string
}

class SocialApi {
    fetchPosts = async (body: FetchPostBodyType): Promise<PostResponse[] & Partial<ErrorResponse>> => {
        return DispatchrService.post(`/social/posts`, body)
    }

    sendPost = async (
        payload: Partial<PayloadPost>,
        onError?: (error: AxiosError) => void
    ): Promise<PostResponse & Partial<ErrorResponse>> => {
        return await DispatchrService.post("/social/post", payload, { onError })
    }

    updatePost = async (
        postId: string,
        payload: Partial<PayloadPost>,
        onError?: (error: AxiosError) => void
    ): Promise<PostResponse & Partial<ErrorResponse>> => {
        return await DispatchrService.put(`/social/post/${postId}`, payload, { onError })
    }

    removePost = async (postId: string): Promise<PostResponse & Partial<ErrorResponse>> => {
        return await DispatchrService.delete(`/social/post/${postId}`)
    }

    getPost = async (id: string): Promise<PostResponse & Partial<ErrorResponse>> => {
        return await DispatchrService.get(`/social/post/${id}`)
    }
}

export default new SocialApi()

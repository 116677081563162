import { MAX_INPUT } from "@/data/limiter"
import useLoadGoogleMap from "@/hooks/useLoadGoogleMap"
import UiStore from "@/store/ui"
import { debounce } from "@mui/material/utils"
import * as React from "react"
import BaseAutocomplete from "../../base/autocomplete"
import { GPlaceCallback, GPlaceInput, GPlaceRequest, PlaceType } from "./types"

const autocompleteService = { current: null }
const placesService = { current: null }

export default function GPlaceInputAddress(props: GPlaceInput) {
    useLoadGoogleMap()

    const { label, onSelect, countryRestrictions, setField, field, defaultValue, required, error } = props
    const [hasValue, setHasValue] = React.useState<boolean>(false)
    const [inputValue, setInputValue] = React.useState<string>("")
    const [options, setOptions] = React.useState<readonly PlaceType[] | any[]>([])
    const { preferredLanguage } = UiStore()
    const [sessionToken, setSessionToken] = React.useState()
    const [open, setOpen] = React.useState(false)

    const fetch = React.useMemo(() => {
        const requestGPlaceApi = (request: GPlaceRequest, callback: GPlaceCallback) => {
            return (autocompleteService.current as any).getPlacePredictions(request, callback)
        }
        return debounce(requestGPlaceApi, 400)
    }, [])

    const requestOpts = React.useMemo(() => {
        return {
            language: preferredLanguage,
            types: ["address"],
            componentRestrictions: {
                country: countryRestrictions || ["CA"],
            },
        }
    }, [preferredLanguage, countryRestrictions])

    React.useEffect(() => {
        if (defaultValue) {
            setTimeout(() => {
                setField({
                    structured_formatting: { main_text: defaultValue, secondary_text: defaultValue },
                    description: defaultValue,
                })
            })
        }
    }, [defaultValue])

    React.useEffect(() => {
        let active = true
        const win: any = window
        if (!autocompleteService.current && win?.google?.maps?.places && !sessionToken) {
            setSessionToken(new win.google.maps.places.AutocompleteSessionToken())
            autocompleteService.current = new win.google.maps.places.AutocompleteService()
            const map = new win.google.maps.Map(document.createElement("div"))
            placesService.current = new win.google.maps.places.PlacesService(map)
        }
        if (!autocompleteService.current) {
            return undefined
        }

        if (inputValue === "" || inputValue?.length < 3) {
            setOpen(false)
            setOptions([])
            return undefined
        }
        if (field?.description === inputValue) {
            setOpen(false)
            return undefined
        }

        const request: GPlaceRequest = {
            input: inputValue,
            sessionToken,
            ...requestOpts,
        }

        fetch(request, (results?: readonly PlaceType[]) => {
            if (active) {
                let newOptions: readonly PlaceType[] = []

                if (results) {
                    newOptions = [...newOptions, ...results]
                }
                setOpen(newOptions?.length > 0)
                setOptions(newOptions)
            }
        })

        return () => {
            active = false
        }
    }, [field, inputValue, fetch, requestOpts, sessionToken, preferredLanguage])

    const handleSelect = React.useCallback(() => {
        if (placesService?.current && (field as any)?.place_id && !hasValue) {
            const data = {
                zip: "",
                city: "",
                province_code: "",
                address: "",
                country: "",
            }
            const opts = {
                placeId: (field as any).place_id,
                fields: ["address_components", "name"],
                language: preferredLanguage,
                sessionToken,
            }
            placesService.current?.getDetails(opts, (place, status) => {
                if (status === "OK") {
                    for (const result of place.address_components) {
                        if (result.types.includes("locality")) {
                            data.city = result.short_name
                        } else if (result.types.includes("sublocality")) {
                            data.city = result.short_name
                        }
                        if (result.types.includes("administrative_area_level_1")) {
                            data.province_code = result.short_name
                        }
                        if (result.types.includes("postal_code")) {
                            data.zip = result.short_name
                        }
                        if (result.types.includes("country")) {
                            data.country = result.short_name
                        }
                    }
                    data.city ??= ""
                    data.province_code ??= ""
                    data.zip ??= ""
                    data.country ??= ""
                    data.address = place.name
                    onSelect(data)
                    setOpen(false)
                    setHasValue(true)
                    setField({ ...field, description: data.address })
                }
            })
        }
    }, [field, hasValue, preferredLanguage, sessionToken, onSelect, setField])

    React.useEffect(() => {
        setHasValue(false)
        handleSelect()
    }, [field])

    return (
        <BaseAutocomplete
            type="google"
            label={label}
            inputCssProps={{
                $requiredelement: props.required,
            }}
            required={required}
            id="google-place"
            inputValue={inputValue}
            getOptionLabel={(option: any) => (typeof option === "string" ? option : option.description) ?? ""}
            open={open}
            options={options}
            freeSolo
            includeInputInList
            filterSelectedOptions
            value={field}
            multiple={false}
            error={error}
            data-testid="google-place-address"
            onClose={() => {
                setOpen(false)
            }}
            onBlur={() => {
                setField({
                    description: inputValue,
                    structured_formatting: {
                        main_text: inputValue,
                        secondary_text: inputValue,
                    },
                })
            }}
            onChange={(_: any, newValue: PlaceType | null) => {
                setOptions(newValue ? [newValue, ...options] : options)
                setField(newValue)
            }}
            onInputChange={(event, newInputValue) => {
                if (newInputValue.length <= MAX_INPUT.TEXT) {
                    setInputValue(newInputValue)
                    if (typeof props?.inputValueChange === "function") {
                        props?.inputValueChange(newInputValue)
                    }
                }
            }}
        />
    )
}

import env from "@/config/env"
import ResellerStore, { INotificationSetter, INotificationStore } from "@/store/notification"
import UiStore from "@/store/ui"

class NotificationService {
    getState = (): INotificationStore & INotificationSetter => {
        return ResellerStore.getState()
    }

    setState = (update: Partial<INotificationStore>): void => {
        const { update: updateState } = this.getState()
        updateState(update)
    }

    showNewVersion = (show: boolean): void => {
        const { setShowNewVersion } = UiStore.getState()
        setShowNewVersion(show)
        localStorage.setItem(`${env.APP_NAME}-${env.ENV}-show-new-version`, String(show))
    }

    updateAppVersion = (version: string): void => {
        localStorage.setItem(`${env.APP_NAME}-${env.ENV}-version`, version)
    }
}

export default new NotificationService()

import { Skeleton, TableCell, TableRow } from "@mui/material"
import { useStyles } from "./styles"

export const TableRowsLoader = ({ rowsNum, cols }) => {
    const { classes } = useStyles()

    return (
        <>
            {[...Array(rowsNum)].map((row, index) => (
                <TableRow key={index} className="gap-2">
                    {[...Array(cols)].map((col, indexCol) => (
                        <TableCell
                            key={indexCol}
                            component="th"
                            scope="row"
                            data-testid="loading-skeleton"
                            className={cols - 1 === indexCol ? classes.lastCols : ""}
                        >
                            <Skeleton animation="wave" variant="text" height={32} width="100%" />
                        </TableCell>
                    ))}
                </TableRow>
            ))}
        </>
    )
}

import Icon from "@/components/base/Icon"
import IconNames from "@/types/icons"
import { Stack, Tooltip, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"

interface StatsType {
    sent: number
    received: number
    opened: number
}

const StatItem = ({
    value = 0,
    icon = "send",
    iconColor = "",
}: {
    value: number
    icon: IconNames
    iconColor?: string
}) => {
    const { t } = useTranslation()
    return (
        <Stack flexDirection={"row"} gap={0.5} justifyContent={"center"} alignItems={"center"}>
            <Tooltip title={t(`CAMPAIGN.TOOLTIP.${icon.toUpperCase()}`)}>
                <span>
                    <Icon
                        name={icon}
                        sx={
                            iconColor
                                ? {
                                      color: iconColor,
                                  }
                                : {}
                        }
                    />
                </span>
            </Tooltip>
            <Typography data-testid={icon} fontFamily={"Poppins Medium"} fontSize={20} fontWeight={500}>
                {value}
            </Typography>
        </Stack>
    )
}

const OverviewsStatistique = ({ stats }: { stats: StatsType }) => {
    const { sent, received, opened } = stats
    return (
        <Stack padding="8px 0px" flexDirection={"row"} justifyContent={"space-between"}>
            <StatItem value={received} icon="send" iconColor="#B337FF" />

            <Icon
                name="chevronRight"
                sx={{
                    color: "#68738DA3",
                }}
            />
            <StatItem value={sent} icon="campaignSent" />

            <Icon
                name="chevronRight"
                sx={{
                    color: "#68738DA3",
                }}
            />
            <StatItem value={opened} icon="campaignOpened" />
        </Stack>
    )
}

export default OverviewsStatistique

import Icon from "@/components/base/Icon"
import { Button, CircularProgress, Stack, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useStyles } from "../../../style"

export const SocialsModalConfirmDeleteContent = ({ handleClose, handleConfirm, isLoading }) => {
    const { classes } = useStyles()
    const { t } = useTranslation()
    return (
        <Stack className={classes.contentAlert} justifyContent={"center"} gap={2} width={"100%"}>
            <Typography variant="body1" sx={{ color: "action.active" }}>
                {t("SOCIALS.ALERT_DELETE.DESCRIPTION")}
            </Typography>
            <Stack className="btn-action" flexDirection={"row"} gap={2}>
                <Button color="secondary" data-testid="close-modal-delete-confirmation" onClick={handleClose}>
                    {t("MEDIA.CANCEL")}
                </Button>
                <Button variant={"contained"} color="error" disabled={isLoading} type="submit" onClick={handleConfirm}>
                    {isLoading && <CircularProgress size={16} sx={{ marginRight: "8px" }} />}
                    {!isLoading && <Icon name="delete1" sx={{ marginRight: "8px" }} />}
                    {t("USERS.DELETE.BUTTON")}
                </Button>
            </Stack>
        </Stack>
    )
}

import SocialsCampaignCard from "@/components/socials/campaign/card"
import useQuerySocial from "@/hooks/social/useQuerySocial"
import useQueryParams from "@/hooks/useQueryParams"
import { SocialService } from "@/services"
import useLocationStore from "@/store/location"
import { PostEntry } from "@/types/socials"
import { Stack } from "@mui/material"
import dayjs from "dayjs"

const SocialsModalPostList = ({ posts = [] }: { posts: Partial<PostEntry>[] }) => {
    const { editPost } = useQuerySocial()
    const { businesses: listBusiness } = useLocationStore()
    const { removeAllQueryParams } = useQueryParams()
    return (
        <Stack
            sx={{
                minHeight: "422px",
            }}
            width={"100%"}
        >
            <Stack
                sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(4, 1fr)",
                    gap: 1,
                    width: "100%",
                    "@media(max-width: 767px)": {
                        gridTemplateColumns: "repeat(2, 10fr)",
                    },
                }}
            >
                {posts?.map((post) => {
                    const date = dayjs(post?.postItems[0]?.scheduledAt).utc() ?? null
                    const isDisabledForm = SocialService.isDisabledForm({
                        postItems: post?.postItems,
                        listBusiness,
                    })
                    return (
                        date && (
                            <SocialsCampaignCard
                                description={post?.description}
                                imageCover={post?.thumbnail}
                                facebook={post?.provider.includes("facebook")}
                                google={post?.provider.includes("google")}
                                instagram={post?.provider.includes("instagram")}
                                postId={post?.id}
                                status={post?.status}
                                date={date}
                                onPostClick={() => {
                                    removeAllQueryParams()
                                    editPost(post?.id, date)
                                }}
                                key={post?.id}
                                isDisabledForm={isDisabledForm}
                            />
                        )
                    )
                })}
            </Stack>
        </Stack>
    )
}

export default SocialsModalPostList
